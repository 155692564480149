import React from "react";
import { useTranslation } from "react-i18next";


const Header = () => {

    const { t } = useTranslation();

    return (
        <section className="header" id="about_me">

        <div className="about_me_left">

            <h1>{t('translations.about_me_left_h1')}</h1>

            <h2>{t('translations.about_me_left_h2')}</h2>

            <div className="buttons_about_me">
                <button className="programar">
                    <a href="https://calendly.com/fabianuribedigital/30min">{t('translations.programar_a')}</a>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 5L19 12L12 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>

                <a className="linkedin" href="https://www.linkedin.com/in/hector-fabian-uribe-isaza/">Linkedin</a>

            </div>

            <div className="about_me_experience">

                <div className="title_experience">
                    <h3>{t('translations.title_experience')}</h3>
                </div>

                <div className="images_experience">
                    <img src="../../sura_logo.png" alt="Sura Logo" />
                    <img src="../../comfenalco_logo.png" alt="Comfenalco Logo" />
                    <img src="../../nutresa_logo.png" alt="Nutresa Logo" />
                    <img src="../../haceb_logo.png" alt="Haceb Logo" />
                </div>

            </div>


        </div>

        <div className="about_me_right">

            <div className="about_me_right_top">

                <div className="about_me_image">
                    <img src="../../fabian_image_landing.png" alt="Fabian Image" />
                </div>

                <div className="about_me_text_experience">

                    <h2>10+</h2>
                    <h3>{t('translations.about_me_text_experience')}</h3>

                    <div className="about_me_text_experience_line">

                        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="8" viewBox="0 0 212 8" fill="none">
                            <path d="M0 4H211" stroke="#D9D9D9" stroke-width="6.65"/>
                            <path d="M0 4H212" stroke="black" stroke-width="6.65"/>
                        </svg>

                    </div>    
                </div>

                <div className="circle_trend">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path d="M46 12L27 31L17 21L2 36" stroke="#A8D67B" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M34 12H46V24" stroke="#A8D67B" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            
            </div>

            <div className="about_me_soluciones">

                <div className="about_me_soluciones_title">

                    <div className="h2_soluciones_title">

                        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="2" viewBox="0 0 54 2" fill="none">
                            <path d="M0 1H54" stroke="white"/>
                        </svg>

                        <h2>{t('translations.h2_soluciones_title')}</h2>

                    </div>

                    <div className="h3_soluciones_text">
                        <h3>{t('translations.h3_soluciones_text')}</h3>
                    </div>                    



                </div>

                <div className="about_me_soluciones_images_background">
                    <img src="../../difuminado_burbujas.png" alt="Burbujas" />
                </div>

                <div className="rectangles_soluciones">

                    <div className="rectangle_1">

                    </div>

                    <div className="rectangle_2">

                    </div>

                    <div className="rectangle_3">

                    </div>


                </div>

            </div>

        </div>
    
    </section>
    )
}

export default Header;