import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {

    const handleChangeLanguage = (event) => {
        const newLang = event.target.value;
        i18n.changeLanguage(newLang);
    };

    const navigate = useNavigate();

    const handleNavigation = (hash) => {
        navigate('/');
        setTimeout(() => {
            window.location.hash = hash;
        }, 10); // Pequeño retraso para permitir la navegación a la raíz primero
    };

        const { t } = useTranslation();

        return (
            <div className="navbar">
                
                <div className="nav_logo_container">
                    <Link to="/">
                        <img src="../../logo_fabi_digital.png" alt="Fabi Digital Logo" />
                    </Link>
                </div>

                <div className="nav_links_container">
                    <button onClick={() => handleNavigation('#about_me')}>{t('translations.AboutMe')}</button>
                    <button onClick={() => handleNavigation('#servicios')}>{t('translations.Servicios')}</button>
                    <button onClick={() => handleNavigation('#cta')}>{t('translations.Contactame')}</button>
                    <button onClick={() => handleNavigation('#faqs')}>FAQs</button>
                    <button onClick={() => handleNavigation('#blog')}>Blog</button>
                </div>

                <div className="buttons_navbar">

                    <select onChange={handleChangeLanguage}>
                        <option value="es">🇪🇸 Español</option>
                        <option value="en">🇺🇸 English</option>
                    </select>

                    <button>
                        <a href="https://calendly.com/fabianuribedigital/30min">{t('translations.hablemos')}</a>
                    </button>

                </div>

                

            </div>
        );
    }

    export default Navbar;