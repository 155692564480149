import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

    const handleNavigation = (hash) => {
        navigate('/');
        setTimeout(() => {
            window.location.hash = hash;
        }, 10); // Pequeño retraso para permitir la navegación a la raíz primero
    };

    const location = window.location.pathname;


  return (
    <footer>
      <div className='footer_container'>
        <div className='footer_logo'>
          <img src="../../logo_fabi_digital.png" alt="Fabi Digital Logo" />
        </div>
        <div className='footer_navegacion'>
            <h3>Navegación</h3>
            
            <button onClick={() => handleNavigation('#servicios')}>Servicios</button>
            <button onClick={() => handleNavigation('#faqs')}>FAQs</button>
            <button onClick={() => handleNavigation('#blog')}>Blog</button>
        </div>
        
        <div className='footer_social'>

          <h3>Contacto</h3>
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M4.0142 5.39531L6.51029 2.90156C6.68637 2.72471 6.89565 2.58439 7.12612 2.48867C7.35659 2.39294 7.6037 2.34369 7.85326 2.34375C8.36185 2.34375 8.83998 2.54297 9.19857 2.90156L11.8845 5.5875C12.0614 5.76358 12.2017 5.97286 12.2974 6.20333C12.3931 6.4338 12.4424 6.68091 12.4423 6.93047C12.4423 7.43906 12.2431 7.91719 11.8845 8.27578L9.92045 10.2398C10.3802 11.2532 11.0194 12.175 11.8072 12.9609C12.593 13.7506 13.5148 14.3922 14.5283 14.8547L16.4923 12.8906C16.6684 12.7138 16.8777 12.5735 17.1082 12.4777C17.3386 12.382 17.5857 12.3328 17.8353 12.3328C18.3439 12.3328 18.822 12.532 19.1806 12.8906L21.8689 15.5742C22.046 15.7506 22.1864 15.9603 22.2821 16.1912C22.3779 16.4221 22.427 16.6696 22.4267 16.9195C22.4267 17.4281 22.2275 17.9062 21.8689 18.2648L19.3775 20.7562C18.8056 21.3305 18.0158 21.6562 17.2048 21.6562C17.0337 21.6562 16.8697 21.6422 16.7079 21.6141C13.5486 21.0938 10.415 19.4133 7.88607 16.8867C5.35951 14.3625 3.68138 11.2312 3.15404 8.0625C2.99467 7.09453 3.31576 6.09844 4.0142 5.39531Z" fill="#192031"/>
            </svg>
            <p>+57 305 4207694</p>
          </p>
          <a href='mailto:fabianuribedigital@gmail.com'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
  <path d="M5.41414 4.7998H21.3141C22.8141 4.7998 23.5701 5.5078 23.5701 6.9478V17.0518C23.5701 18.4798 22.8141 19.1998 21.3141 19.1998H5.41414C3.91414 19.1998 3.15814 18.4798 3.15814 17.0518V6.9478C3.15814 5.5078 3.91414 4.7998 5.41414 4.7998ZM13.3581 15.1198L21.4461 8.4838C21.7341 8.2438 21.9621 7.6918 21.6021 7.1998C21.2541 6.7078 20.6181 6.6958 20.1981 6.9958L13.3581 11.6278L6.53014 6.9958C6.11014 6.6958 5.47414 6.7078 5.12614 7.1998C4.76614 7.6918 4.99414 8.2438 5.28214 8.4838L13.3581 15.1198Z" fill="#192031"/>
</svg>
          <p>fabianuribedigital@gmail.com</p>
          </a>
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19" fill="none">
              <g clip-path="url(#clip0_23_2464)">
                <path d="M6.90638 18.525C5.00326 16.1426 0.770447 10.3684 0.770447 7.125C0.770447 3.18992 3.9044 0 7.77045 0C11.635 0 14.7704 3.18992 14.7704 7.125C14.7704 10.3684 10.5048 16.1426 8.63451 18.525C8.18607 19.0928 7.35482 19.0928 6.90638 18.525ZM7.77045 9.5C9.05743 9.5 10.1038 8.43496 10.1038 7.125C10.1038 5.81504 9.05743 4.75 7.77045 4.75C6.48347 4.75 5.43711 5.81504 5.43711 7.125C5.43711 8.43496 6.48347 9.5 7.77045 9.5Z" fill="#192031"/>
              </g>
              <defs>
                <clipPath id="clip0_23_2464">
                  <rect width="14" height="19" fill="white" transform="translate(0.770447)"/>
                </clipPath>
              </defs>
            </svg>
            <p>Medellín - Colombia</p>
          </p>
        </div>

      {location === '/' ? (
                <div className='coopiright'>

                <div className='footer_redes'>
                  <a href='https://www.facebook.com/profile.php?id=61558687890791&mibextid=LQQJ4d'><svg xmlns="http://www.w3.org/2000/svg" width="7" height="15" viewBox="0 0 7 15" fill="none">
        <g clip-path="url(#clip0_23_2433)">
          <path d="M1.78669 14.1045V7.53529H-0.000244141V5.17006H1.78669V3.14984C1.78669 1.56234 2.84225 0.104492 5.27446 0.104492C6.25923 0.104492 6.98742 0.196262 6.98742 0.196262L6.93004 2.40499C6.93004 2.40499 6.1874 2.39796 5.377 2.39796C4.49991 2.39796 4.35939 2.79087 4.35939 3.443V5.17006H6.99976L6.88487 7.53529H4.35939V14.1045H1.78669Z" fill="#192031"/>
        </g>
        <defs>
          <clipPath id="clip0_23_2433">
            <rect width="7" height="14" fill="white" transform="translate(-0.000244141 0.104492)"/>
          </clipPath>
        </defs>
      </svg></a>
                  <a href='https://www.instagram.com/fabianuribedigital/'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
        <g clip-path="url(#clip0_23_2445)">
          <path d="M8.00344 5.06181C5.73201 5.06181 3.89987 6.86533 3.89987 9.10127C3.89987 11.3372 5.73201 13.1407 8.00344 13.1407C10.2749 13.1407 12.107 11.3372 12.107 9.10127C12.107 6.86533 10.2749 5.06181 8.00344 5.06181ZM8.00344 11.7274C6.53558 11.7274 5.33558 10.5497 5.33558 9.10127C5.33558 7.65283 6.53201 6.47509 8.00344 6.47509C9.47486 6.47509 10.6713 7.65283 10.6713 9.10127C10.6713 10.5497 9.47129 11.7274 8.00344 11.7274ZM13.232 4.89658C13.232 5.42041 12.8034 5.83877 12.2749 5.83877C11.7427 5.83877 11.3177 5.41689 11.3177 4.89658C11.3177 4.37627 11.7463 3.95439 12.2749 3.95439C12.8034 3.95439 13.232 4.37627 13.232 4.89658ZM15.9499 5.85283C15.8891 4.59072 15.5963 3.47275 14.657 2.55166C13.7213 1.63057 12.5856 1.34229 11.3034 1.279C9.98201 1.20518 6.0213 1.20518 4.69987 1.279C3.4213 1.33877 2.28558 1.62705 1.3463 2.54814C0.407014 3.46924 0.117728 4.58721 0.0534423 5.84931C-0.0215576 7.15009 -0.0215576 11.0489 0.0534423 12.3497C0.114157 13.6118 0.407014 14.7298 1.3463 15.6509C2.28558 16.572 3.41773 16.8602 4.69987 16.9235C6.0213 16.9974 9.98201 16.9974 11.3034 16.9235C12.5856 16.8638 13.7213 16.5755 14.657 15.6509C15.5927 14.7298 15.8856 13.6118 15.9499 12.3497C16.0249 11.0489 16.0249 7.15361 15.9499 5.85283ZM14.2427 13.7454C13.9641 14.4345 13.4249 14.9653 12.7213 15.2431C11.6677 15.6544 9.16772 15.5595 8.00344 15.5595C6.83915 15.5595 4.33558 15.6509 3.28558 15.2431C2.58558 14.9688 2.0463 14.438 1.76416 13.7454C1.3463 12.7083 1.44273 10.2474 1.44273 9.10127C1.44273 7.95517 1.34987 5.49072 1.76416 4.45713C2.04273 3.76806 2.58201 3.23721 3.28558 2.95947C4.33915 2.54814 6.83915 2.64307 8.00344 2.64307C9.16772 2.64307 11.6713 2.55166 12.7213 2.95947C13.4213 3.23369 13.9606 3.76455 14.2427 4.45713C14.6606 5.49424 14.5641 7.95517 14.5641 9.10127C14.5641 10.2474 14.6606 12.7118 14.2427 13.7454Z" fill="#192031"/>
        </g>
        <defs>
          <clipPath id="clip0_23_2445">
            <rect width="16" height="18" fill="white" transform="translate(0.00012207 0.104492)"/>
          </clipPath>
        </defs>
      </svg></a>
                  <a href='https://www.linkedin.com/in/hector-fabian-uribe-isaza/'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_23_2441)">
          <path d="M3.63281 15.1045V4.98402H0.202508V15.1045H3.63281ZM1.9181 3.60141C3.11431 3.60141 3.85889 2.82428 3.85889 1.85312C3.8366 0.860057 3.11436 0.104492 1.9408 0.104492C0.767434 0.104492 0.00012207 0.860072 0.00012207 1.85312C0.00012207 2.82432 0.74452 3.60141 1.89571 3.60141H1.918H1.9181ZM5.53147 15.1045H8.96177V9.45273C8.96177 9.15026 8.98406 8.8481 9.07465 8.63187C9.32263 8.02754 9.88705 7.40162 10.8346 7.40162C12.0759 7.40162 12.5725 8.3297 12.5725 9.6902V15.1044H16.0026V9.30144C16.0026 6.19283 14.3103 4.74642 12.0533 4.74642C10.2028 4.74642 9.39033 5.76075 8.93895 6.45161H8.96185V4.98381H5.53155C5.57657 5.93346 5.53155 15.1043 5.53155 15.1043L5.53147 15.1045Z" fill="#192031"/>
        </g>
        <defs>
          <clipPath id="clip0_23_2441">
            <rect width="16" height="15" fill="white" transform="translate(0.00012207 0.104492)"/>
          </clipPath>
        </defs>
      </svg></a>
                </div>
                
      
                <p>© Copyright Fabian Uribe 2024</p>
      
              </div>
      ) : location === '/servicios' ? (
        (
          <div className='coopiright'>
  
                          <div className='footer_redes'>
                    <a href='https://www.facebook.com/profile.php?id=61558687890791&mibextid=LQQJ4d'><svg xmlns="http://www.w3.org/2000/svg" width="7" height="15" viewBox="0 0 7 15" fill="none">
          <g clip-path="url(#clip0_23_2433)">
            <path d="M1.78669 14.1045V7.53529H-0.000244141V5.17006H1.78669V3.14984C1.78669 1.56234 2.84225 0.104492 5.27446 0.104492C6.25923 0.104492 6.98742 0.196262 6.98742 0.196262L6.93004 2.40499C6.93004 2.40499 6.1874 2.39796 5.377 2.39796C4.49991 2.39796 4.35939 2.79087 4.35939 3.443V5.17006H6.99976L6.88487 7.53529H4.35939V14.1045H1.78669Z" fill="#192031"/>
          </g>
          <defs>
            <clipPath id="clip0_23_2433">
              <rect width="7" height="14" fill="white" transform="translate(-0.000244141 0.104492)"/>
            </clipPath>
          </defs>
        </svg></a>
                    <a href='https://www.instagram.com/fabianuribedigital/'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
          <g clip-path="url(#clip0_23_2445)">
            <path d="M8.00344 5.06181C5.73201 5.06181 3.89987 6.86533 3.89987 9.10127C3.89987 11.3372 5.73201 13.1407 8.00344 13.1407C10.2749 13.1407 12.107 11.3372 12.107 9.10127C12.107 6.86533 10.2749 5.06181 8.00344 5.06181ZM8.00344 11.7274C6.53558 11.7274 5.33558 10.5497 5.33558 9.10127C5.33558 7.65283 6.53201 6.47509 8.00344 6.47509C9.47486 6.47509 10.6713 7.65283 10.6713 9.10127C10.6713 10.5497 9.47129 11.7274 8.00344 11.7274ZM13.232 4.89658C13.232 5.42041 12.8034 5.83877 12.2749 5.83877C11.7427 5.83877 11.3177 5.41689 11.3177 4.89658C11.3177 4.37627 11.7463 3.95439 12.2749 3.95439C12.8034 3.95439 13.232 4.37627 13.232 4.89658ZM15.9499 5.85283C15.8891 4.59072 15.5963 3.47275 14.657 2.55166C13.7213 1.63057 12.5856 1.34229 11.3034 1.279C9.98201 1.20518 6.0213 1.20518 4.69987 1.279C3.4213 1.33877 2.28558 1.62705 1.3463 2.54814C0.407014 3.46924 0.117728 4.58721 0.0534423 5.84931C-0.0215576 7.15009 -0.0215576 11.0489 0.0534423 12.3497C0.114157 13.6118 0.407014 14.7298 1.3463 15.6509C2.28558 16.572 3.41773 16.8602 4.69987 16.9235C6.0213 16.9974 9.98201 16.9974 11.3034 16.9235C12.5856 16.8638 13.7213 16.5755 14.657 15.6509C15.5927 14.7298 15.8856 13.6118 15.9499 12.3497C16.0249 11.0489 16.0249 7.15361 15.9499 5.85283ZM14.2427 13.7454C13.9641 14.4345 13.4249 14.9653 12.7213 15.2431C11.6677 15.6544 9.16772 15.5595 8.00344 15.5595C6.83915 15.5595 4.33558 15.6509 3.28558 15.2431C2.58558 14.9688 2.0463 14.438 1.76416 13.7454C1.3463 12.7083 1.44273 10.2474 1.44273 9.10127C1.44273 7.95517 1.34987 5.49072 1.76416 4.45713C2.04273 3.76806 2.58201 3.23721 3.28558 2.95947C4.33915 2.54814 6.83915 2.64307 8.00344 2.64307C9.16772 2.64307 11.6713 2.55166 12.7213 2.95947C13.4213 3.23369 13.9606 3.76455 14.2427 4.45713C14.6606 5.49424 14.5641 7.95517 14.5641 9.10127C14.5641 10.2474 14.6606 12.7118 14.2427 13.7454Z" fill="#192031"/>
          </g>
          <defs>
            <clipPath id="clip0_23_2445">
              <rect width="16" height="18" fill="white" transform="translate(0.00012207 0.104492)"/>
            </clipPath>
          </defs>
        </svg></a>
                    <a href='https://www.linkedin.com/in/hector-fabian-uribe-isaza/'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <g clip-path="url(#clip0_23_2441)">
            <path d="M3.63281 15.1045V4.98402H0.202508V15.1045H3.63281ZM1.9181 3.60141C3.11431 3.60141 3.85889 2.82428 3.85889 1.85312C3.8366 0.860057 3.11436 0.104492 1.9408 0.104492C0.767434 0.104492 0.00012207 0.860072 0.00012207 1.85312C0.00012207 2.82432 0.74452 3.60141 1.89571 3.60141H1.918H1.9181ZM5.53147 15.1045H8.96177V9.45273C8.96177 9.15026 8.98406 8.8481 9.07465 8.63187C9.32263 8.02754 9.88705 7.40162 10.8346 7.40162C12.0759 7.40162 12.5725 8.3297 12.5725 9.6902V15.1044H16.0026V9.30144C16.0026 6.19283 14.3103 4.74642 12.0533 4.74642C10.2028 4.74642 9.39033 5.76075 8.93895 6.45161H8.96185V4.98381H5.53155C5.57657 5.93346 5.53155 15.1043 5.53155 15.1043L5.53147 15.1045Z" fill="#192031"/>
          </g>
          <defs>
            <clipPath id="clip0_23_2441">
              <rect width="16" height="15" fill="white" transform="translate(0.00012207 0.104492)"/>
            </clipPath>
          </defs>
        </svg></a>
  
                  </div>
                  
  
                  <p>© Copyright Fabian Uribe 2024</p>
  
  
                </div>
  
                  
        
                 
        )
      ) :  (
        <div className='coopiright'>

                        <div className='footer_redes'>
                  <a href='https://www.facebook.com/profile.php?id=61558687890791&mibextid=LQQJ4d'><svg xmlns="http://www.w3.org/2000/svg" width="7" height="15" viewBox="0 0 7 15" fill="none">
        <g clip-path="url(#clip0_23_2433)">
          <path d="M1.78669 14.1045V7.53529H-0.000244141V5.17006H1.78669V3.14984C1.78669 1.56234 2.84225 0.104492 5.27446 0.104492C6.25923 0.104492 6.98742 0.196262 6.98742 0.196262L6.93004 2.40499C6.93004 2.40499 6.1874 2.39796 5.377 2.39796C4.49991 2.39796 4.35939 2.79087 4.35939 3.443V5.17006H6.99976L6.88487 7.53529H4.35939V14.1045H1.78669Z" fill="#192031"/>
        </g>
        <defs>
          <clipPath id="clip0_23_2433">
            <rect width="7" height="14" fill="white" transform="translate(-0.000244141 0.104492)"/>
          </clipPath>
        </defs>
      </svg></a>
                  <a href='https://www.instagram.com/fabianuribedigital/'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
        <g clip-path="url(#clip0_23_2445)">
          <path d="M8.00344 5.06181C5.73201 5.06181 3.89987 6.86533 3.89987 9.10127C3.89987 11.3372 5.73201 13.1407 8.00344 13.1407C10.2749 13.1407 12.107 11.3372 12.107 9.10127C12.107 6.86533 10.2749 5.06181 8.00344 5.06181ZM8.00344 11.7274C6.53558 11.7274 5.33558 10.5497 5.33558 9.10127C5.33558 7.65283 6.53201 6.47509 8.00344 6.47509C9.47486 6.47509 10.6713 7.65283 10.6713 9.10127C10.6713 10.5497 9.47129 11.7274 8.00344 11.7274ZM13.232 4.89658C13.232 5.42041 12.8034 5.83877 12.2749 5.83877C11.7427 5.83877 11.3177 5.41689 11.3177 4.89658C11.3177 4.37627 11.7463 3.95439 12.2749 3.95439C12.8034 3.95439 13.232 4.37627 13.232 4.89658ZM15.9499 5.85283C15.8891 4.59072 15.5963 3.47275 14.657 2.55166C13.7213 1.63057 12.5856 1.34229 11.3034 1.279C9.98201 1.20518 6.0213 1.20518 4.69987 1.279C3.4213 1.33877 2.28558 1.62705 1.3463 2.54814C0.407014 3.46924 0.117728 4.58721 0.0534423 5.84931C-0.0215576 7.15009 -0.0215576 11.0489 0.0534423 12.3497C0.114157 13.6118 0.407014 14.7298 1.3463 15.6509C2.28558 16.572 3.41773 16.8602 4.69987 16.9235C6.0213 16.9974 9.98201 16.9974 11.3034 16.9235C12.5856 16.8638 13.7213 16.5755 14.657 15.6509C15.5927 14.7298 15.8856 13.6118 15.9499 12.3497C16.0249 11.0489 16.0249 7.15361 15.9499 5.85283ZM14.2427 13.7454C13.9641 14.4345 13.4249 14.9653 12.7213 15.2431C11.6677 15.6544 9.16772 15.5595 8.00344 15.5595C6.83915 15.5595 4.33558 15.6509 3.28558 15.2431C2.58558 14.9688 2.0463 14.438 1.76416 13.7454C1.3463 12.7083 1.44273 10.2474 1.44273 9.10127C1.44273 7.95517 1.34987 5.49072 1.76416 4.45713C2.04273 3.76806 2.58201 3.23721 3.28558 2.95947C4.33915 2.54814 6.83915 2.64307 8.00344 2.64307C9.16772 2.64307 11.6713 2.55166 12.7213 2.95947C13.4213 3.23369 13.9606 3.76455 14.2427 4.45713C14.6606 5.49424 14.5641 7.95517 14.5641 9.10127C14.5641 10.2474 14.6606 12.7118 14.2427 13.7454Z" fill="#192031"/>
        </g>
        <defs>
          <clipPath id="clip0_23_2445">
            <rect width="16" height="18" fill="white" transform="translate(0.00012207 0.104492)"/>
          </clipPath>
        </defs>
      </svg></a>
                  <a href='https://www.linkedin.com/in/hector-fabian-uribe-isaza/'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_23_2441)">
          <path d="M3.63281 15.1045V4.98402H0.202508V15.1045H3.63281ZM1.9181 3.60141C3.11431 3.60141 3.85889 2.82428 3.85889 1.85312C3.8366 0.860057 3.11436 0.104492 1.9408 0.104492C0.767434 0.104492 0.00012207 0.860072 0.00012207 1.85312C0.00012207 2.82432 0.74452 3.60141 1.89571 3.60141H1.918H1.9181ZM5.53147 15.1045H8.96177V9.45273C8.96177 9.15026 8.98406 8.8481 9.07465 8.63187C9.32263 8.02754 9.88705 7.40162 10.8346 7.40162C12.0759 7.40162 12.5725 8.3297 12.5725 9.6902V15.1044H16.0026V9.30144C16.0026 6.19283 14.3103 4.74642 12.0533 4.74642C10.2028 4.74642 9.39033 5.76075 8.93895 6.45161H8.96185V4.98381H5.53155C5.57657 5.93346 5.53155 15.1043 5.53155 15.1043L5.53147 15.1045Z" fill="#192031"/>
        </g>
        <defs>
          <clipPath id="clip0_23_2441">
            <rect width="16" height="15" fill="white" transform="translate(0.00012207 0.104492)"/>
          </clipPath>
        </defs>
      </svg></a>

                </div>
                

                <p>© Copyright Fabian Uribe 2024</p>


              </div>

                
      
               
      )}



      </div>



    </footer>
  );
};

export default Footer;