import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Card({title, text, read_time, link}) {
  return (
      <div className="blog_card">

          <div className="blog_card_header">
              <div className="point_blog"></div>
              <div className="time_read">
                  <h3>{read_time}</h3>
              </div>
          </div>
          <div className="blog_card_title">
              <h3>{title}</h3>
          </div>
          <div className="blog_card_body">
              <p>{text}</p>
              <Link to={link}>
                  <button>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" fill="none">
                          <path d="M1.33307 1.5L8.33307 8.5L1.33307 15.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                  </button>
              </Link>
          </div>
      </div>
  )
}


function Blog1() {

  const { t } = useTranslation();
  
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div>
      <Navbar />
        <div className='article_blog_container'>

            <div className='article_blog_title'>

              <div className='title_blog_header'>
                <h1>El valor estratégico de los consultores digitales para las PYMES</h1>
              </div>

              <div className='article_blog_subtitle'>
                <p>Te comparto datos  y aspectos clave sobre consultores digitales.</p>
              </div>

            </div>

            <div className='article_blog_body'>

              <p>
              Contratar un consultor digital puede ser una decisión estratégica que potencie el crecimiento y la eficiencia de las Pequeñas y Medianas Empresas (PyMEs). Estos expertos en tecnología y transformación digital pueden aportar un valor significativo en varios aspectos clave:
                <h3>1. Visión Externa y Objetiva</h3>
                Los consultores digitales ofrecen una perspectiva fresca y objetiva. Al no estar inmersos en la operación diaria de la empresa, pueden identificar oportunidades y desafíos desde un punto de vista imparcial. Esto es especialmente valioso para las PyMEs que pueden estar atrapadas en patrones de pensamiento limitados.

                <h3>2. Estrategia Digital Personalizada</h3>

                Cada empresa es única, y un buen consultor digital adapta las estrategias a las necesidades específicas de la organización. Ayudan a definir metas claras, seleccionar las herramientas tecnológicas adecuadas y diseñar un plan de acción para alcanzar el éxito en el mundo digital.

                <h3>3. Optimización de Procesos</h3>

                Los consultores digitales analizan los procesos internos de la empresa y proponen mejoras. Pueden identificar áreas donde la automatización, la integración de sistemas o la reingeniería de procesos pueden aumentar la eficiencia y reducir
                costos.

                <h3>4. Implementación de Soluciones Tecnológicas </h3>

                Desde la elección de un sistema de gestión empresarial hasta la implementación de estrategias de marketing digital, los consultores ayudan a las PyMEs a adoptar tecnologías que impulsen su crecimiento. Además, brindan capacitación al personal para maximizar el uso de estas herramientas.

                <h3>5. Gestión del Cambio</h3>

                La transformación digital implica cambios en la cultura organizacional y en la forma de trabajar. Los consultores digitales guían a las PyMEs a través de este proceso, asegurándose de que todos los miembros del equipo estén alineados y
                comprometidos con la visión digital.
              </p>

            </div>

            <div className='suggest_blog_articles'>

              <h2>{t('translations.suggest_blog_articles')}</h2>
              

              <div className="blog_carousel_blog">

              <Card
                  title={t('translations.card_1_title')}
                  text={t('translations.card_1_text')}
                  read_time={t('translations.card_read_time')}
                  link="/blog/1/consultores-digitales-pyme"
                />
                <Card
                  title={t('translations.card_2_title')}
                  text={t('translations.card_2_text')}
                  read_time={t('translations.card_read_time')}
                  link="/blog/2/exito-digital-pyme"
                />
                <Card
                  title={t('translations.card_3_title')}
                  text={t('translations.card_3_text')}
                  read_time={t('translations.card_read_time')}
                  link="/blog/3/tendencias-digitales-pyme"
                />

              </div>

            </div>

        </div>

      <Footer />
    </div>
  );
}

export default Blog1;