import { Routes, Route, BrowserRouter } from 'react-router-dom';
import LandingFabianDigital from './components/Home'
import Blog1 from './blog/blog1'; 
import Blog2 from './blog/blog2';
import Blog3 from './blog/blog3';
import './styles.css'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingFabianDigital />} />
        <Route path="/blog/1/consultores-digitales-pyme" element={<Blog1 />} />
        <Route path="/blog/2/exito-digital-pyme" element={<Blog2 />} />
        <Route path="/blog/3/tendencias-digitales-pyme" element={<Blog3 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;