import React from "react";
import Navbar from "./Navbar";
import Header from "./Header";
import Servicios from "./Servicios";
import Agendar from "./Agendar";
import Reviews from "./Reviews";
import FAQs from "./FAQs";
import Blog from "./Blog";
import CTA from "./CTA";
import Footer from "./Footer";

const LandingFabianDigital = () => {
    return (
        <>
            <Navbar />

            <Header />

            <Servicios />

            <Agendar />

            <Reviews />

            <FAQs />

            <Blog />

            <CTA />

            <Footer /> 

        </>
    );
}

export default LandingFabianDigital;