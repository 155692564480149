import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


function Card({title, text, read_time, link}) {
  return (
      <div className="blog_card">

          <div className="blog_card_header">
              <div className="point_blog"></div>
              <div className="time_read">
                  <h3>{read_time}</h3>
              </div>
          </div>
          <div className="blog_card_title">
              <h3>{title}</h3>
          </div>
          <div className="blog_card_body">
              <p>{text}</p>
              <Link to={link}>
                  <button>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" fill="none">
                          <path d="M1.33307 1.5L8.33307 8.5L1.33307 15.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                  </button>
              </Link>
          </div>
      </div>
  )
}


function Blog3() {

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div>
      <Navbar />
        <div className='article_blog_container'>

            <div className='article_blog_title'>

              <div className='title_blog_header'>
                <h1>Navegando las Olas Digitales: Tendencias para las PYMES en América</h1>
              </div>

              <div className='article_blog_subtitle'>
                <p>Hablemos sobre transformación digital y como las empresas se enfrentas a nuevos desafios y oportunidades. </p>
              </div>

            </div>

            <div className='article_blog_body'>

              <p>
                Las Pequeñas y Medianas Empresas (PyMEs) en Latinoamérica están experimentando una transformación digital acelerada. Estas empresas, que constituyen el corazón de la economía en la región, se enfrentan a nuevos desafíos y oportunidades en el mundo digital. A continuación, presento cuatro hallazgos clave sobre las tendencias tecnológicas que están moldeando el recorrido de las PyMEs en LATAM:
                <h3>Desafíos Comerciales</h3>
                El 95% de las empresas encuestadas considera positiva la transformación digital, pero también ha traído consigo retos en la forma de hacer negocios. En Chile y Costa Rica, el desafío principal está relacionado con las ventas; en Brasil, con la adquisición de clientes; mientras que, en Perú, la productividad es un reto. Las PyMEs utilizan canales digitales para sus operaciones comerciales, lo que requiere
                creatividad y un manejo adecuado de los datos para entender mejor a los clientes y desarrollar estrategias efectivas.
                <h3>Optimización de Datos</h3>
                La recopilación de datos ha aumentado gracias a la transformación digital. Las PyMEs deben priorizar la inteligencia del negocio mediante el uso eficiente de estos datos. Conocer a los clientes, comprender sus comportamientos y preferencias es fundamental para mejorar las operaciones empresariales.

                <h3>Modalidad de Trabajo Híbrido</h3>
                La modalidad de trabajo híbrido prevalece en Latinoamérica. Las PyMEs han adoptado modelos flexibles que combinan el trabajo presencial y remoto, aprovechando las herramientas digitales para mantener la productividad y la colaboración.
                <h3>Ciberseguridad</h3>
                La seguridad digital es una preocupación y una prioridad. Las PyMEs deben proteger sus datos, sistemas y redes ante las crecientes amenazas cibernéticas. La inversión en soluciones de seguridad es esencial para garantizar la continuidad del negocio.
              </p>

            </div>

            <div className='suggest_blog_articles'>

              <h2>{t('translations.suggest_blog_articles')}</h2>

              <div className="blog_carousel_blog">

              <Card
                  title={t('translations.card_1_title')}
                  text={t('translations.card_1_text')}
                  read_time={t('translations.card_read_time')}
                  link="/blog/1/consultores-digitales-pyme"
                />
                <Card
                  title={t('translations.card_2_title')}
                  text={t('translations.card_2_text')}
                  read_time={t('translations.card_read_time')}
                  link="/blog/2/exito-digital-pyme"
                />
                <Card
                  title={t('translations.card_3_title')}
                  text={t('translations.card_3_text')}
                  read_time={t('translations.card_read_time')}
                  link="/blog/3/tendencias-digitales-pyme"
                />   

              </div>

            </div>

        </div>

      <Footer />
    </div>
  );
}

export default Blog3;