import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";


function ReviewCard({ text, author, role_author, avatar, handlePrev, handleNext, currentIndex, totalReviews }) {
    return (
      <div className="review_card">
        <div className="review_text">
          <p>{text}</p>
        </div>
  
        <div className='review_author_card'>

          <div className='review_author_avatar'>
            <img src={avatar} alt={author} />
          </div>

          <div className="review_author">
            <h4>{author}</h4>
            <p>{role_author}</p>
          </div>

        </div>

        <div className='carousel_dots'>

          <button onClick={handlePrev}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M19 12H5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 5L5 12L12 19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          </button>
          <p>{currentIndex + 1}/{totalReviews}</p>
          <button onClick={handleNext}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M5 12H19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 5L19 12L12 19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          </button>

        </div>


      </div>
    );
  }

const Reviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);

  const { t } = useTranslation();

  const reviews = [
    {
      text: t('translations.reviews.review_1.review'),
      author: t('translations.reviews.review_1.name'),
      role_author: t('translations.reviews.review_1.position'),
      avatar: '../../foto_carlos.png',
    },
    {
      text:  t('translations.reviews.review_2.review'),
      author: t('translations.reviews.review_2.name'),
      role_author: t('translations.reviews.review_2.position'),
      avatar: '../../foto_alexandra.png',

    },

  ];

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextClick();
    }, 15000);

    return () => clearInterval(interval);

  }, [currentIndex])


  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex === 0) {
        return reviews.length - 1;
      }
      return prevIndex - 1;
    });
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex === reviews.length - 1) {
        return 0;
      }
      return prevIndex + 1;
    });
  };

  useEffect(() => {
    setTotalReviews(reviews.length);
  }, [])

  return (
    <section className="carousel" id='reviews'>
      <ReviewCard
        text={reviews[currentIndex].text}
        author={reviews[currentIndex].author}
        role_author={reviews[currentIndex].role_author}
        avatar={reviews[currentIndex].avatar}
        handlePrev={handlePrevClick}
        handleNext={handleNextClick}
        currentIndex={currentIndex}
        totalReviews={totalReviews}
      />


    </section>
  );
};

export default Reviews;