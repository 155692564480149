import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Card({title, text, read_time, link}) {
  return (
      <div className="blog_card">

          <div className="blog_card_header">
              <div className="point_blog"></div>
              <div className="time_read">
                  <h3>{read_time}</h3>
              </div>
          </div>
          <div className="blog_card_title">
              <h3>{title}</h3>
          </div>
          <div className="blog_card_body">
              <p>{text}</p>
              <Link to={link}>
                  <button>
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" fill="none">
                          <path d="M1.33307 1.5L8.33307 8.5L1.33307 15.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                  </button>
              </Link>
          </div>
      </div>
  )
}


function Blog2() {

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div>
      <Navbar />
        <div className='article_blog_container'>

            <div className='article_blog_title'>

              <div className='title_blog_header'>
                <h1>El Camino hacia el Éxito de las PyMEs</h1>
              </div>

              <div className='article_blog_subtitle'>
                <p>Hablemos sobre la estrategia digital, la competitividad, sostenibilidad e innovación.</p>
              </div>

            </div>

            <div className='article_blog_body'>

              <p>
                La transformación digital no es solo una cuestión tecnológica, sino una estrategia integral que impacta en la competitividad, la sostenibilidad y la innovación de las PyMEs. Algunas acciones clave para impulsar el éxito empresarial en Latinoamérica son:
                  <h3>1. Educación y Capacitación</h3>
                  Las PyMEs deben invertir en la formación de su personal para que estén preparados para adoptar y aprovechar las tecnologías digitales. Esto incluye desde habilidades técnicas hasta la comprensión de las implicancias estratégicas.
                  <h3>2. Alianzas Estratégicas</h3>
                  Colaborar con otras empresas, instituciones académicas y organismos gubernamentales puede abrir nuevas oportunidades. Las alianzas pueden facilitar el acceso a recursos, conocimientos y mercados.
                  <h3>3. Innovación Continua</h3>
                  Las PyMEs deben estar dispuestas a experimentar y adaptarse constantemente. La innovación no solo se trata de grandes cambios, sino también de pequeñas mejoras que suman a lo largo del tiempo.
                  <h3>4. Conciencia Digital</h3>
                  Es fundamental que las PyMEs comprendan la importancia de la transformación digital y estén dispuestas a invertir en ella. Esto implica no solo recursos económicos, sino también un cambio cultural y mentalidad abierta al cambio.  comprometidos con la visión digital.
              </p>

            </div>

            <div className='suggest_blog_articles'>

              <h2>{t('translations.suggest_blog_articles')}</h2>


              <div className="blog_carousel_blog">

              <Card
                  title={t('translations.card_1_title')}
                  text={t('translations.card_1_text')}
                  read_time={t('translations.card_read_time')}
                  link="/blog/1/consultores-digitales-pyme"
                />
                <Card
                  title={t('translations.card_2_title')}
                  text={t('translations.card_2_text')}
                  read_time={t('translations.card_read_time')}
                  link="/blog/2/exito-digital-pyme"
                />
                <Card
                  title={t('translations.card_3_title')}
                  text={t('translations.card_3_text')}
                  read_time={t('translations.card_read_time')}
                  link="/blog/3/tendencias-digitales-pyme"
                />       

              </div>

            </div>

        </div>

      <Footer />
    </div>
  );
}

export default Blog2;