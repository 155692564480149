import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


function Card({title, text, read_time, link}) {
    return (
        <div className="blog_card">

            <div className="blog_card_header">
                <div className="point_blog"></div>
                <div className="time_read">
                    <h3>{read_time}</h3>
                </div>
            </div>
            <div className="blog_card_title">
                <h3>{title}</h3>
            </div>
            <div className="blog_card_body">
                <p>{text}</p>
                <Link to={link}>
                    <button>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="17" viewBox="0 0 10 17" fill="none">
                            <path d="M1.33307 1.5L8.33307 8.5L1.33307 15.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </Link>
            </div>
        </div>
    )
}


const Blog = () => {

    const { t } = useTranslation();

    return (    
        <section className="blog" id="blog">
            <div className="blog_title">
                <h2>{t('translations.blog_title')}</h2>
                <div className="subtitle_blog">
                <p>{t('translations.blog_subtitle')}</p>
                </div>
            </div>
            <div className="blog_carousel">
                <Card
                title={t('translations.card_1_title')}
                text={t('translations.card_1_text')}
                read_time={t('translations.card_read_time')}
                link="/blog/1/consultores-digitales-pyme"
                />
                <Card
                title={t('translations.card_2_title')}
                text={t('translations.card_2_text')}
                read_time={t('translations.card_read_time')}
                link="/blog/2/exito-digital-pyme"
                />
                <Card
                title={t('translations.card_3_title')}
                text={t('translations.card_3_text')}
                read_time={t('translations.card_read_time')}
                link="/blog/3/tendencias-digitales-pyme"
                />
            </div>
        </section>
    );
}

export default Blog;