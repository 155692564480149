import React, { useState } from "react";
import { useTranslation } from "react-i18next";


const Servicios = () => {

    const { t } = useTranslation();

    const [activeButton, setActiveButton] = useState("Estrategia Digital");
    const buttonContent = {
        "Estrategia Digital": (
          <>
            <div className="servicios_card_image">
                <img src="../../estrategia_digital.png" alt="Estrategia digital" />
            </div>
            <div className="servicios_card_text">
                <div className="servicios_card_title">
                    <h3>{t("translations.servicios_card_title_estrategia")}</h3>
                </div>
                <div className="servicios_card_list">
                    <ul>
                        <li>{t("translations.servicios_card_list_estrategia_1")}</li>
                        <li>{t("translations.servicios_card_list_estrategia_2")}</li>
                        <li>{t("translations.servicios_card_list_estrategia_3")}</li>
                        <li>{t("translations.servicios_card_list_estrategia_4")}</li>
                    </ul>
                </div>
            </div>

          </>
        ),
        "Experiencia de Usuario (CX)": (
          <>
            <div className="servicios_card_image">
                <img src="../../experiencia_de_usuario.png" alt="Experiencia de usuario" />
            </div>
            <div className="servicios_card_text">
                <div className="servicios_card_title">
                    <h3>{t("translations.servicios_card_title_expericia")}</h3>
                </div>
                <div className="servicios_card_list">
                    <ul>
                        <li>{t("translations.servicios_card_list_experiencia_1")}</li>
                        <li>{t("translations.servicios_card_list_experiencia_2")}</li>
                    </ul>
                </div>
            </div>
          </>
        ),
        "Sitios Web y UX/UI Marketing": (
          <>
            <div className="servicios_card_image">
                <img src="../../sitios_web.png" alt="Sitios web y UX/UI marketing" />
            </div>
            <div className="servicios_card_text">
                <div className="servicios_card_title">
                    <h3>{t("translations.servicios_card_title_web")}</h3>
                </div>
                <div className="servicios_card_list">
                    <ul>
                        <li>{t("translations.servicios_card_list_sitios_web_1")}</li>
                        <li>{t("translations.servicios_card_list_sitios_web_2")}</li>
                    </ul>
                </div>
            </div>
          </>
        ),
        "Análisis de Datos (DATA)": (
          <>
            <div className="servicios_card_image">  
                <img src="../../analisis_de_datos.png" alt="Analisis de datos" />
            </div>
            <div className="servicios_card_text">
            <div className="servicios_card_title">
                <h3>{t("translations.servicios_card_title_analisis_datos")}</h3>
            </div>
            <div className="servicios_card_list">
                <ul>
                    <li>{t("translations.servicios_card_list_analisis_datos_1")}</li>
                    <li>{t("translations.servicios_card_list_analisis_datos_2")}</li>
                </ul>
            </div>
            </div>
          </>
        ),
        "Liderazgo y Herramientas": (
          <>
            <div className="servicios_card_image">
                <img src="../../liderazgo.png" alt="Liderazgo y herramientas" />
            </div>
            <div className="servicios_card_text">
            <div className="servicios_card_title">
                <h3>{t("translations.servicios_card_title_liderazgo")}</h3>
            </div>
            <div className="servicios_card_list">
                <ul>
                    <li>{t("translations.servicios_card_list_liderazgo_1")}</li>
                    <li>{t("translations.servicios_card_list_liderazgo_2")}</li>
                    <li>{t("translations.servicios_card_list_liderazgo_3")}</li>
                    <li>{t("translations.servicios_card_list_liderazgo_4")}</li>
                </ul>
            </div>
            </div>

          </>
        )
      };

    return (
        <section className="servicios" id="servicios">
            
            <div className="servicios_background">
                <img src="../../background_servicios.png" alt="Burbujas" />
            </div>

            <div className="servicios_container">

                <div className="servicios_title">
                    <h2>{t("translations.servicios_title")}</h2>
                </div>

                <div className="servicios_nav_cards">
                    {Object.keys(buttonContent).map(buttonLabel => (
                        <button
                        key={buttonLabel}
                        onClick={() => setActiveButton(buttonLabel)}
                        className={activeButton === buttonLabel ? "active" : ""}
                        >
                        <p>{buttonLabel}</p>
                        </button>
                    ))}
                </div>

                <div className="servicios_cards">
                    {buttonContent[activeButton]}
                </div>


            </div>
        </section>
    )
}

export default Servicios;