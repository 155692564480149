import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "../i18n";


const FAQs = () => {

  const translations = {
    es: {
      questions: [
        {
          question: "¿Por qué es importante la consultoría digital para mi empresa?:",
          answer: "La consultoría digital permite a las empresas llegar a un público más amplio, generar clientes potenciales, atraer tráfico a su sitio web y aumentar la visibilidad de su marca. Proporciona resultados cuantificables, permite realizar acciones de marketing específicas y adaptar y optimizar sus estrategias en función de los datos y la información disponible.",
        },
        {
          question: "¿Qué es la Transformación Digital y por qué es importante para mi empresa?:",
          answer: "La Transformación Digital implica integrar tecnología digital en todos los aspectos de tu empresa, cambiando la forma en que operas y brindas valor a tus clientes. Es vital para competir y prosperar en el mercado actual.",
        },
        {
          question: "¿Cómo puedo empezar con la Transformación Digital en mi empresa?:",
          answer: "Comienza evaluando tu situación actual y definiendo objetivos claros. Luego, identifica las áreas clave para la digitalización y crea un plan de acción.",
        },
        {
          question: "¿Qué tecnologías son esenciales para la Transformación Digital de mi empresa?:",
          answer: "Algunas tecnologías clave incluyen la nube, análisis de datos, automatización y seguridad cibernética. Adaptarlas a tus necesidades específicas es crucial.",
        },
        {
          question: "¿Cómo afectará la Transformación Digital a mis empleados y cómo puedo prepararlos?: ",
          answer: "La digitalización cambiará la forma en que trabajan. Proporciona capacitación, comunica los beneficios y fomenta una mentalidad abierta al cambio.",
        },
      ]
    },
    en: {
      questions: [
        {
          question: "Why is digital consulting important for my business?:",
          answer: "Digital consulting allows companies to reach a wider audience, generate leads, drive traffic to their website and increase their brand visibility. It provides quantifiable results, allows you to perform targeted marketing actions and to adapt and optimize your strategies based on the data and information available.",
        },
        {
          question: "What is Digital Transformation and why is it important for my business?:",
          answer: "Digital Transformation involves integrating digital technology into all aspects of your business, changing the way you operate and deliver value to your customers. It is vital to competing and thriving in today's marketplace.",
        },
        {
          question: "How can I get started with Digital Transformation in my company?:",
          answer: "Start by assessing your current situation and defining clear objectives. Then, identify the key areas for digitization and create an action plan.",
        },
        {
          question: "What technologies are essential for the Digital Transformation of my company?",
          answer: "Some key technologies include cloud, data analytics, automation and cyber security. Tailoring these to your specific needs is crucial.",
        },
        {
          question: "How will Digital Transformation affect my employees and how can I prepare them: ",
          answer: "Digitalization will change the way they work. Provide training, communicate the benefits and encourage a mindset that is open to change.",
        },


      ]
    }
  };

  const [preguntas, setPreguntas] = useState(translations.es.questions);
  const [preguntaActiva, setPreguntaActiva] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleChangeLanguage = (newLang) => {
      setPreguntas(translations[newLang].questions);
    };

    handleChangeLanguage(i18n.language);

    const languageChangeHandler = () => {
      handleChangeLanguage(i18n.language);
    };

    i18n.on('languageChanged', languageChangeHandler);

    return () => {
      i18n.off('languageChanged', languageChangeHandler);
    };
  }, []);

  const handleTogglePregunta = (pregunta) => {
    setPreguntaActiva(preguntaActiva === pregunta ? null : pregunta);
  };

  return (
    <section className="faqs" id='faqs'>

      <div className='faqs_text'>

        <h2>{t('translations.faqs_text_h2')}</h2>

        <p>{t('translations.faqs_text_p')}</p>

      </div>

      <div className='faqs_questions'>

      {preguntas.map((pregunta, index) => (
        <div key={index} className="pregunta">
          <button onClick={() => handleTogglePregunta(pregunta)}>
            <h2>{pregunta.question} {preguntaActiva === pregunta ? '-' : '+'}</h2>	
            <hr />
          </button>
          {preguntaActiva === pregunta && (
            <div className="respuesta">
              <p>{pregunta.answer}</p>
            </div>
          )}
        </div>
      ))}

      </div>

    </section>
  );
};

export default FAQs;