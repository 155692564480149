import React from "react";
import { useTranslation } from "react-i18next";


const CTA = () => {

    const { t } = useTranslation();

    return (
        <section className="cta" id="cta">
            <h2>{t('translations.cta_h2')}</h2>
            <button onClick={() => window.location.href = 'https://calendly.com/fabianuribedigital/30min'}>
                {t('translations.cta_button')}
            </button>
        </section>
    );
}

export default CTA;